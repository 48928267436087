<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                <b-form-group
                  label-for="service_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('teaGardenBtriService.service_id')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="teaPlantSupply.service_id"
                  :options="organizationList"
                  id="service_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Planting Material Name (En)" vid="title_en" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="title_en">
                  <template v-slot:label>
                    {{ $t('teaGardenBtriService.planting_material_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="title_en"
                    v-model="teaPlantSupply.title_en"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Planting Material Name (Bn)" vid="title_bn" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="title_bn">
                  <template v-slot:label>
                    {{ $t('teaGardenBtriService.planting_material_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="title_bn"
                    v-model="teaPlantSupply.title_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col>
                <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </ValidationObserver>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { plantingMaterialStore, plantingMaterialUpdate } from '../../../api/routes'
  export default {
    name: 'Form',
    props: ['id'],
    data () {
      return {
        valid: null,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.saves'),
        errors: [],
        teaPlantSupply: {
          service_id: 0,
          title_en: '',
          title_bn: ''
        }
      }
    },
    created () {
      if (this.id) {
        const tmp = this.getteaPlantSupply()
        this.teaPlantSupply = tmp
      }
    },
    computed: {
      loading: function () {
        return this.$store.state.commonObj.loading
      },
      organizationList () {
        return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => item.value === 20 || item.value === 21).map(item => {
          return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
        })
      }
    },
    methods: {
      async saveUpdate () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        if (this.id) {
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${plantingMaterialUpdate}/${this.id}`, this.teaPlantSupply)
        } else {
          result = await RestApi.postData(teaGardenServiceBaseUrl, plantingMaterialStore, this.teaPlantSupply)
        }
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      },
      getteaPlantSupply () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      }
    }
  }
</script>
